@import 'components/global.scss';

.largeHeader {
	position: fixed;
	width: 100%;
	z-index: 3;
	background: #283038;
	padding: 1.5625em 1.5625em 1.5625em 16.5625em;
	color: #ffffff;
	height: 5em;
	left: 0;

	@media (max-width: $mobile) {
		color: $c-textPrimary;
		text-align: center;
		background: #283038;
		padding: $scale $scale;
		border: none;
	}
}

.largeHeader-nosidebar {
	position: fixed;
	width: 100%;
	z-index: 3;
	background: #283038;
	padding: 1.5625em 1.5625em 1.5625em 1.5625em;
	color: #ffffff;
	height: 5em;
	left: 0;

	@media (max-width: $mobile) {
		color: $c-textPrimary;
		text-align: center;
		background: #283038;
		padding: $scale $scale;
		border: none;
	}
}
.gridG {
	//max-width: 69.5625em;
}
