@import '../../global.scss';
.gridContainer {
	// display: grid;
	// grid-template-columns: repeat(7, 3fr);
	// grid-template-rows: 1fr;
	//gap: 1px 2px;
	// grid-template-areas: '. . . . . . .';
	width: 100%;
	display: flex;
	min-height: 1.5em;
	//max-height: 1.5em; // CHANGED!
}
.gridItem {
	// max-height: 8.5em;
	// min-height: 4.5em;
	min-height: 6.5em;
	max-height: 6.5em;
	padding: 0;
	//min-width: 10.25em;
	min-width: 6.9em;
	//margin: 0em 0.03125em !important;
	width: 100%;
	@media (max-width: 1430px) {
		//min-width: 10.25em - $scale-1;
		//min-width: 6.9em - $scale-1;
		min-width: 6.73em;
		width: 100%;
		min-height: 6.5em;
		max-height: 6.5em;
	}
	> div {
		box-shadow: none !important;
	}
}

.gridItem-compact {
	//min-height: 6.5em;
	//max-height: 6.5em;
	padding: 0;

	min-width: 4em;

	width: 100%;
	@media (max-width: 1430px) {
		//min-width: 10.25em - $scale-1;
		//min-width: 6.9em - $scale-1;
		min-width: 4em;
		width: 100%;
		// min-height: 6.5em;
		// max-height: 6.5em;
	}
	> div {
		box-shadow: none !important;
	}
}
.grid-card-start {
	border-radius: 0.25em 0px 0px 0.25em !important;
	//border-right: 1px solid $c-black-Background !important;
}
.grid-card {
	border-radius: 0px 0px !important;
	padding: 0px;
	border-right: 1px solid $c-black-Background !important;
	border-left: 1px solid $c-black-Background !important;
}
.grid-card-end {
	border-radius: 0px 0.25em 0.25em 0px !important;
}

// .MuiCardContent-root {
//     padding: 7px;
// }
// .MuiCardContent-root:last-child {
//     /* padding-bottom: 24px; */
// }
// .MuiCardContent-root {
//     padding: 15px;
// }
