@import '../global.scss';

.grid-container-root {
	padding: 0.5em 0.5em 0em 0.5em !important;
}

.form-control-root {
	div {
		&::before {
			border-bottom: unset !important;
		}
		&::after {
			border-bottom: unset !important;
		}
		div {
			padding: 0 0.5em !important;
			color: $c-ElectricBlue !important;
		}
	}
	svg {
		display: none;
	}
}

.selected-item {
	color: $c-ElectricBlue !important;
	background-color: $c-black-Sidebar !important;
}

.dropdown-menu {
	background-color: $c-black-Background !important;
	li {
		&:hover {
			background-color: $c-black-Sidebar !important;
		}
	}
}
