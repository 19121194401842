@import 'components/global.scss';

.label {
	display: block;
	position: relative;
	color: $c-text-White;
	font-size: 0.85em;
	margin-bottom: 0.65em;
	text-transform: uppercase;
}

.legend {
	margin-bottom: $scale1;
}

.required:after {
	content: '*';
	color: $c-Danger;
	margin-left: 0.35em;
	font-size: $scale;
	font-weight: 500;
}
