@import 'components/global.scss';

main {
	font-size: 16px;
	&.with-sidebarMUI {
		//padding-left: $scale12 + $scale1;
		margin-left: $sidebar-width1;
		padding: 0 1em;

		//max-width: 72em;
		min-width: 72em;

		display: flex;
		justify-content: center;

		.blankslate-message {
			margin-left: ($scale11 + $scale2) / 2;
		}
		@media (max-width: 1430px) {
			margin-left: $sidebar-width-1;
			min-width: 72em - $scale3;
		}
	}
}

header {
	margin-bottom: $scale;
}

.col1 {
	@media (min-width: $mobile) {
		float: left;
		width: 49%;
	}
}

.col2 {
	@media (min-width: $mobile) {
		float: right;
		width: 49%;
	}
}

.title-row {
	&:after {
		content: '';
		display: table;
		clear: both;
	}

	h2 {
		float: left;
		margin-top: 0;
	}

	button {
		float: right;
		margin-top: -0.5em;
	}
}
