@import 'components/global.scss';

.notification {

  position: fixed;
  left: 0;
  right: 0;
  color: white;
  top: -$scale5;
  z-index: 10;
  text-align: center;
  padding: 0.75em $scale;
  transition: all 0.25s ease-out;

  &.success {

    background-color: $c-green;

  }

  &.warning {

    background-color: $c-orange;

  }

  &.error {

    background-color: $c-red;

  }

  .btn-close-notification {

    position: absolute;
    top: 50%;
    right: $scale2;
    opacity: 0.7;
    width: 1.5em;
    height: 1.5em;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transition: all 0.2s ease-in-out;

    &:hover {

      opacity: 1;

    }
  }
}

.notification-appear-done {

  top: 0;

}

.notification-exit {

  top: -$scale5;

}
