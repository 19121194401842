@import 'components/global.scss';
.gridAgents {
	display: flex;
	margin-bottom: 16px;
	width: 100%;
}

.gridItem {
	max-height: 9.25em;
	min-height: 9.25em;
	//min-width: 14em;
	min-width: 14.4em;
	margin: 0em 0.03125em !important;
	width: 100%;

	@media (max-width: 1430px) {
		min-width: 14.4em - $scale-1;
	}
}
.grid-card-start {
	border-radius: 0.625em 0px 0px 0.625em !important;
}
.grid-card {
	border-radius: 0px 0px !important;
	padding: 0px;
}
.grid-card-end {
	border-radius: 0px 0.625em 0.625em 0px !important;
}
.card-avatar {
	margin-bottom: 0.5em;
}
//148px h
//224 w
//border-radius: 10px;

/*
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;


*/
