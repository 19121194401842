@import 'components/global.scss';

.header {
	// position: relative;
	// width: auto;
	position: fixed;
	width: 100%;
	z-index: 3;
	background: #283038;
	padding: $scale2;
	margin: $scale2 * -1 $scale2 * -1 $scale2 $scale2 * -1;
	color: $c-textPrimary;

	@media (max-width: $mobile) {
		color: $c-textPrimary;
		text-align: center;
		background: #283038;
		padding: $scale $scale;
		border: none;
	}
}
