@import 'components/global.scss';

.app {
	//padding: $scale2;
	min-height: 100%;
	background-color: $c-bg;
	//padding-left: 11.3em;
}
// .headerTitle{
//   padding-left: 7em;
// }
.formContainerM {
	width: 100%;
	max-width: 1300px;
	position: absolute;
	top: 50%;
	z-index: 11;
	transform: translateY(-50%);
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
}
.formDividerM {
	background-color: #171c22 !important;
	width: 5px !important;
	margin: 0 10px !important;
}
.formBtn {
	margin-left: 10px !important;
}
.formControlM {
	margin: 8px;
	min-width: 120px;

	//position: absolute!important;
	//top: 50%;
	//z-index: 11;
	//transform: translateY(-50%);
	right: 1.5625em;
	text-align: right;
} //TODO: material palette
