/* colours */
$c-bg: #171c22;
$c-text: #707070;
$c-textPrimary: #ffffff;
$c-text-light: lighten($c-text, 20%);
//$c-border: darken(white, 5%);
$c-border: darken(white, 5%);
$c-border-dark: lighten($c-text, 52%);
$c-primary: #73b0f4;
$c-dark: #333440;

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;

$c-red: #d95565;
$c-green: #8cc57d;
$c-blue: #73b0f4;
$c-darkblue: #3a4488;
$c-orange: #f0aa61;
$c-tint: #fbfbff;
$c-purple: #6363ac;
$c-darkpurple: #556cb6;

//MUI color palette
//dark theme
$c-black-Background: #171c22; /* Blacks / Background */
$c-black-Module: #283038; /* Blacks / Module */
$c-black-Sidebar: #0d1014; /* Blacks / Sidebar */
$c-text-Grey: #8b8ba1; /* Text / Grey */
$c-text-White: #ffffff; /* Text / White */
//light theme
$c-ElectricBlue: #1ff2ff; /* Electric Blue */
$c-Teal: #14909b; /* Teal */
$c-Danger: #e56161; /* Danger */
$c-Purple: #6179e5; /* Purple */
$c-Pink: #c361e5; /* Pink */
$c-greys-1: #dce1eb; /* Greys / 1 */
$c-Yellow: #e5b061; /* yellow */

:export {
	c-black-Background: $c-black-Background;
	c-black-Module: $c-black-Module;
	c-black-Sidebar: $c-black-Sidebar;
	c-text-Grey: $c-text-Grey;
	c-text-White: $c-text-White;
	c-Teal: $c-Teal;
	c-Purple: $c-Purple;
	c-Danger: $c-Danger;
}
