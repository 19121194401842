@import '../../global.scss';

.gridContainer {
	display: flex;

	//width: 100%;
	justify-content: space-between;
}

.gridItem {
	max-height: 8.5em;
	min-height: 8.5em;
	min-width: 10.25em;
	margin: 0em 0.03125em !important;
}
.grid-card-start {
	border-radius: 0.25em 0px 0px 0.25em !important;
}
.grid-card {
	border-radius: 0px 0px !important;
	padding: 0px;
}
.grid-card-end {
	border-radius: 0px 0.25em 0.25em 0px !important;
}

.comparison-paper-container {
	display: flex;
	background-color: #3e454c !important;
	padding: 8px;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 40px !important;
	width: 122px;
	height: 40px;
}
.comparison-paper-item {
	display: flex;
	padding: 4px;
	border-radius: 50% !important;
	background-color: #51585e !important;
}

.comparison-icon-positive {
	transform: rotate(0deg);
}
.comparison-icon-negative {
	transform: rotate(90deg);
}
.comparison-icon-equal {
	transform: rotate(45deg);
}
