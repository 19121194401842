// .highchartMe > div > svg > .highcharts-xaxis-labels > text > :first-child {
// 	fill: red;
// 	color: red;
// }
@import '../global.scss';

.highchartMe > div > svg > g > text > :last-child {
	fill: $c-text-Grey !important;

	//color: green !important;
}
//highcharts-axis-labels highcharts-xaxis-labels
