@import '../global.scss';

.table-root {
	border-collapse: separate !important;
	border-spacing: 0 10px !important;
}
.tableHead-root {
	height: 2em;
	max-height: 2em;
	min-height: 2em;
	box-sizing: border-box;
	th {
		padding: 0 1em;
		height: 2em;
		background-color: $c-black-Background;
		border-top: 1px solid #283038;
		border-bottom: 1px solid #283038;
	}
	th:first-child {
		padding: 0 1em;
		height: 2em;
		border-top: 1px solid #283038;
		border-left: 1px solid #283038;
		border-bottom: 1px solid #283038;
		border-top-left-radius: 0.625em;
		border-bottom-left-radius: 0.625em;
	}
	th:last-child {
		padding: 0 1em;
		height: 2em;
		border-top: 0.0625em solid #283038;
		border-right: 0.0625em solid #283038;
		border-bottom: 0.0625em solid #283038;
		border-top-right-radius: 0.625em;
		border-bottom-right-radius: 0.625em;
	}
}
.icon-root {
	color: #8b8ba1;
	text-align: center;
	font-size: 12px !important;
}

.header-text {
	font-weight: 900;
	font-size: 0.75em;
	line-height: 1em;
	letter-spacing: 0.1em;

	color: #8b8ba1 !important;
	&:hover {
		color: #fff !important;
	}
	&:focus {
		color: #fff !important;
	}
}

.header-text-active {
	font-weight: 900;
	font-size: 0.75em;
	line-height: 1em;
	letter-spacing: 0.1em;

	color: #fff !important;
}
.row-root {
	background-color: transparent !important;
	th {
		padding: 0.6em 1em;
		background: #283038;
		border-bottom: none !important;
	}
	td {
		padding: 0.6em 1em;
		background: #283038;
		border-bottom: none !important;
	}
	th:first-child {
		border-top-left-radius: 0.625em;
		border-bottom-left-radius: 0.625em;
	}
	td:first-child {
		border-top-left-radius: 0.625em;
		border-bottom-left-radius: 0.625em;
	}

	td:last-child {
		border-top-right-radius: 0.625em;
		border-bottom-right-radius: 0.625em;
	}
}

.table-cell-root {
	font-size: 1em !important;
	p {
		font-size: 0.9em;
	}
}

.table-cell-root-alt {
	font-size: 0.75em !important;

	font-size: 0.9em;
	font-weight: 900;
	font-size: 0.75em;
	line-height: 1em;
	letter-spacing: 0.1em;

	color: #8b8ba1 !important;
	font-weight: 900;
	//font-size: 0.75em;
	line-height: 1em;
	letter-spacing: 0.1em;
}

//.table-
