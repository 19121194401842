@import 'components/global.scss';

.animate.pop > * {

  opacity: 0;
  transform: scale(0.95);
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

}

.animate.pop-appear-done > * {

  opacity: 1;
  transform: none;

}

.animate.slideup > * {

  opacity: 0;
  transform: translateY(5%);
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

}

.animate.slideup-appear-done > * {

  opacity: 1;
  transform: none;

}

.animate.slidedown > * {

  opacity: 0;
  transform: translateY(-5%);
  transition: all 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

}

.animate.slidedown-appear-done > * {

  opacity: 1;
  transform: none;

}
