@import 'components/global.scss';

.auth {
	min-height: 100%;
	background-color: $c-bg;
	span {
		// display: block;
		// width: 100%;
		// text-align: center;
		// margin-top: $scale1;
	}
}
