@import 'components/global.scss';

.form {
	> header {
		padding: 0 0 1em 0 !important;

		h2 {
			margin-bottom: $scale/3;
		}

		p {
			color: lighten($c-text, 16%);
			font-size: $scale-1;
		}
	}

	> a {
		display: block;
		margin-bottom: $scale;
	}
	> div > input {
		&:focus {
			background-color: $c-black-Background;
		}
		padding: 0.7em 1em;
		color: $c-text-Grey;
		background-color: $c-black-Sidebar;
		border-color: $c-text-Grey;
		/* Change the white to any color ;) */
		&:-webkit-autofill,
		:-webkit-autofill:hover,
		:-webkit-autofill:focus,
		:-webkit-autofill:active {
			-webkit-box-shadow: 0 0 0 50px $c-black-Background inset;
			-webkit-text-fill-color: $c-text-Grey !important;
		}
	}
	> div > label {
		color: $c-text-White;
	}
	> div > div {
		background-color: $c-Danger;
	}
	> label {
		color: $c-text-White;
	}
	> div > button {
		background-color: #6179e5;
	}
}

.loading {
	opacity: 0.5;
}
