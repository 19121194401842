@import 'components/global.scss';

.label {
	margin: 0 0 0.25em;
}

.bar {
	width: 100%;
	height: 0.75em;
	margin-bottom: $scale;
	background-color: #ebeff4;
}

.fill {
	height: 100%;
	transition: all 0.5s;
	background-color: $c-green;
}
