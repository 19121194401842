@import '../global.scss';

.navTabs-root {
	background-color: $c-black-Module;
	position: fixed;
	width: 100%;
	margin-top: 0.25em;
	left: $sidebar-width1;
	min-height: 3em;
	z-index: 50;

	//

	//margin-top: 5.2em; // general
	margin-top: 0.2em; //hardcoded for now before bugfix
	div > div > button > span {
		font-size: 1.6rem;
		font-weight: 800;
		text-transform: capitalize;
		font-style: normal;
		font-family: AvenirHeavy, cursive;
	}
	@media (max-width: 1430px) {
		left: $sidebar-width-1;
	}
	div > span {
		background-color: $c-ElectricBlue;
	}
}

.navTabs-root-Alt {
	width: 100%;
	// min-height: 20px !important;
	// line-height: 1px;

	div {
		//height: 40px;
		> span {
			background-color: transparent;
			//height: 0;
		}
		> div > button {
			//
			padding: 0;
			min-width: 50px !important;
			margin: 0 10px;
			> span > div > :last-child {
				> div {
					font-size: 16px;
				}
			}
		}
	}
}
