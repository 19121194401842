// @import '../global.scss';

.pwd-change-alt {
	//
	//color: red;

	> div {
		background-color: #262c3575 !important;
		> div {
			background-color: #262c3575 !important;
		}
	}
}
